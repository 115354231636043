import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import AppBar from '../components/BlogAppBar';
import { Divider } from '@mui/material';

const base_url = process.env.NODE_ENV === 'development' ? 'http://localhost:7074' : 'https://api.heysabio.com';

const MAX_WIDTH = '1440';

const Subtitle = ({ content }) => {
  return (
    <Box>
      <Typography sx={{ color: '#272620', mb: 1, mt: 5, fontWeight: 400, fontSize: 18 }}>{content}</Typography>
      <Divider sx={{ mb: 5 }} />
    </Box>
  );
};

const BodyText = ({ content }) => {
  return <Typography sx={{ overflowWrap: 'break-word', color: '#272620' }}>{content}</Typography>;
};

const Bullets = ({ content }) => {
  return (
    <Box sx={{ mt: 5 }}>
      {content.map((point, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
            •
          </Typography>
          <Typography>{point}</Typography>
        </Box>
      ))}
    </Box>
  );
};

const BlogPost = () => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  const [blog, setBlog] = useState();

  useEffect(() => {
    const retrieve = async () => {
      let blogTitle = window.location.href.split('/')[window.location.href.split('/').length - 1];
      blogTitle = blogTitle.replaceAll('-', ' ');

      const { data } = await axios.get(`${base_url}/blog/retrieve/${blogTitle}`);

      setLoading(false);

      if (!data) {
        setNotFound(true);
      } else {
        setBlog(data);
      }
    };

    retrieve();
  }, []);

  return (
    <Container maxWidth={MAX_WIDTH} disableGutters sx={{ mt: 12, height: '100vh' }}>
      <AppBar />
      {loading && (
        <Box sx={{ width: '100%', height: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {blog && (
        <Box>
          <Box
            sx={{
              px: { md: 6, xs: 2 },
              py: { md: 3, xs: 3 },
              mb: { md: 3, xs: 3 },
              mt: { md: 15, xs: 10 },
              bgcolor: '#EE6E12',
              borderTopRightRadius: 15,
              borderTopLeftRadius: 15,
            }}
          >
            <Typography variant="h2">{blog.title}</Typography>
          </Box>
          <Box sx={{ width: '70%', px: { md: 6, xs: 3 }, py: { md: 2, xs: 2 } }}>
            {blog.content.map((content, index) => {
              if (content.type === 'subtitle') {
                return <Subtitle key={index} content={content.content} />;
              }
              if (content.type === 'body') {
                return <BodyText key={index} content={content.content} />;
              }
              if (content.type === 'bullets') {
                return <Bullets key={index} content={content.content} />;
              }
              return null;
            })}
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default BlogPost;
