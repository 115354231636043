import React from 'react';
import Container from '@mui/material/Container';
import axios from 'axios';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AppBar from '../components/BlogAppBar';
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import RssFeedIcon from '@mui/icons-material/RssFeed';

const base_url = process.env.NODE_ENV === 'development' ? 'http://localhost:7074' : 'https://api.heysabio.com';

const MAX_WIDTH = '1440';

const Blog = () => {
  const [featured, setFeatured] = React.useState([]);
  const [blogList, setBlogList] = React.useState([]);

  const urlEnd = window.location.href.split('/')[window.location.href.split('/').length - 1];

  React.useEffect(() => {
    const fetchBlogs = async () => {
      const { data } = await axios.get(`${base_url}/blog/retrieve`);

      if (data && data.length > 0) {
        setFeatured(data.filter((d) => d.featured)[0]);
        setBlogList(data.filter((d) => !d.featured));
      }
    };

    fetchBlogs();
  }, []);

  const handleBlogPress = (title) => {
    const formatted = title.replace(/\s+/g, '-');
    window.location.href = `/blog/${formatted}`;
  };

  return (
    <Container maxWidth={MAX_WIDTH} disableGutters sx={{ mt: 12, height: '100vh' }}>
      <AppBar />
      {urlEnd === 'blog' ? (
        <>
          <Box
            sx={{
              px: { md: 6, xs: 3 },
              py: { md: 3, xs: 3 },
              mb: { md: 3 },
              mt: { md: 15 },
              bgcolor: '#EE6E12',
              borderTopRightRadius: 15,
              borderTopLeftRadius: 15,
            }}
          >
            <Typography variant="h1">Sabio's Space</Typography>
          </Box>
          <List sx={{ p: 3 }}>
            {featured && (
              <ListItem>
                <ListItemButton onClick={() => handleBlogPress(featured.title)} sx={{ borderRadius: 2 }}>
                  <ListItemIcon>
                    <FeaturedPlayListIcon />
                  </ListItemIcon>
                  <ListItemText primary={featured.title} />
                </ListItemButton>
              </ListItem>
            )}
            {blogList.map((blog) => {
              return (
                <ListItem>
                  <ListItemButton onClick={() => handleBlogPress(blog.title)} sx={{ borderRadius: 2 }}>
                    <ListItemIcon>
                      <RssFeedIcon />
                    </ListItemIcon>
                    <ListItemText primary={blog.title} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </>
      ) : null}
      <Outlet />
    </Container>
  );
};

export default Blog;
